import { createStructuredContent, updateStructuredContent } from '@lumapps/structured-content/api';
import { STRUCTURED_CONTENT_FORMAT } from '@lumapps/structured-content/types';
import { TranslateObject } from '@lumapps/translations';
import { LegacyWidget } from '@lumapps/widget-base/types';
import { setTitle } from '@lumapps/wrex/serialization/dita/utils/setTitle';

import { LegacyWidgetContributionProps } from '../../type';
import { toStructuredContentApiLanguage } from '../../utils/toStructuredContentApiLanguage';

/** This function saves a structured content from a contribution widget */
export const saveStructuredContentFromWidget = async (
    contributionWidget: LegacyWidget<LegacyWidgetContributionProps>,
    title: TranslateObject<string>,
    shouldUseRichText: boolean,
) => {
    if (contributionWidget.properties && !shouldUseRichText) {
        // Do not save RT if FF is not enabled
        // eslint-disable-next-line no-param-reassign
        delete contributionWidget.properties.richText;
    }

    const widgetProperties = contributionWidget.properties;
    const dita = widgetProperties?.dita;
    const richText = widgetProperties?.richText;

    let textualContent;

    if (shouldUseRichText && richText) {
        const mappedLanguagesRichText = Object.keys(richText).reduce((acc, cur) => {
            return {
                ...acc,
                [toStructuredContentApiLanguage(cur)]: richText[cur],
            };
        }, {});
        textualContent = mappedLanguagesRichText;
    } else if (dita) {
        const newDita = Object.keys(dita).reduce((acc, cur) => {
            return {
                ...acc,
                [toStructuredContentApiLanguage(cur)]: setTitle(dita[cur], title[cur]),
            };
        }, {});
        textualContent = newDita;
    }

    if (textualContent && widgetProperties) {
        if (widgetProperties.structuredContentId) {
            const { id, version } = await updateStructuredContent(
                widgetProperties.structuredContentId,
                textualContent,
                shouldUseRichText ? STRUCTURED_CONTENT_FORMAT.RICH_TEXT : STRUCTURED_CONTENT_FORMAT.DITA,
            );

            Object.assign(widgetProperties, {
                structuredContentId: id,
                structuredContentVersion: version,
            });
        } else {
            const { id, version } = await createStructuredContent(
                textualContent,
                shouldUseRichText ? STRUCTURED_CONTENT_FORMAT.RICH_TEXT : STRUCTURED_CONTENT_FORMAT.DITA,
            );

            Object.assign(widgetProperties, {
                structuredContentId: id,
                structuredContentVersion: version,
            });
        }
    } else {
        throw new Error();
    }
};
