/* eslint-disable no-underscore-dangle */
import includes from 'lodash/includes';
import get from 'lodash/get';
import map from 'lodash/map';
import set from 'lodash/set';

import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

/////////////////////////////

function NewsletterThemeController(
    $element,
    $scope,
    $state,
    $timeout,
    $window,
    Config,
    Content,
    ContentPicker,
    Customer,
    Document,
    Feed,
    FroalaService,
    InitialSettings,
    Instance,
    LxDialogService,
    LxNotificationService,
    Newsletter,
    Translation,
    UploaderAction,
    UserAccess,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The types of files allowed for upload in the newsletter header.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.ALLOWED_IMAGE_TYPE = Config.FILE_TYPES.IMAGE;

    /**
     * The identifier of the settings dialog.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.DIALOG_SETTINGS_ID = 'newsletter-theme-settings';

    /**
     * The identifier of the dialog to send the newsletter.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.DIALOG_SEND_ID = 'newsletter-theme-send';

    /**
     * The identifier of the list of contents of the newsletter.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.KEY_CONTENT_LIST = 'newsletter-content-list';

    /**
     * The default set of toolbar buttons to use in the wysiwyg.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _NEWSLETTER_TOOLBAR_BUTTONS = [
        'fullscreen',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontFamily',
        'fontSize',
        'color',
        '|',
        'paragraphFormat',
        'align',
        'formatOL',
        'formatUL',
        'outdent',
        'indent',
        'insertHR',
        '|',
        'nativeInsertLink',
        'insertImage',
        'insertTable',
        'undo',
        'redo',
        'clearFormatting',
        'selectAll',
        'html',
    ];

    /**
     * The content block for each section of the newsletter.
     *
     * @type {Object}
     */
    vm.contentBlockContents = {};

    /**
     * Indicates if the newsletter is currently rendering or not.
     *
     * @type {boolean}
     */
    vm.isRendering = false;

    /**
     * Whether we are currently sending the newsletter.
     *
     * @type {boolean}
     */
    vm.isSending = false;

    /**
     * An object that hold the temporary values of new emails.
     *
     * @type {Object}
     */
    vm.newEmail = {
        test: undefined,
        to: undefined,
    };

    /**
     * Future google groups to add to the list.
     * This Google Groups will be selected by feed-selector if the user has restricted rights in roles.
     *
     * @type {Array}
     */
    vm.newGoogleGroups = [];

    /**
     * The current date.
     *
     * @type {Object}
     */
    vm.now = moment();

    /**
     * List of restricted feed ids for the newsletter.
     *
     * @type {Array}
     */
    vm.restrictedFeeds = [];

    /**
     * The mode in which we want to send the newsletter.
     * Either 'test' or 'to'.
     *
     * @type {string}
     */
    vm.sendMode = 'test';

    /**
     * The additional fields to set on the content picker.
     *
     * @type {Object}
     */
    vm.contentPickerProjection = { excerpt: true };

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Content = Content;
    vm.Document = Document;
    vm.Feed = Feed;
    vm.Instance = Instance;
    vm.LxDialogService = LxDialogService;
    vm.Newsletter = Newsletter;
    vm.Translation = Translation;
    vm.UploaderAction = UploaderAction;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Add the email string to the list of emails.
     * The email string could be add to the tests emails or production emails.
     *
     * @param {string}  emailStr    The new email to add.
     * @param {boolean} isTestEmail If it's a test email or a production one.
     */
    function _addTextEmail(emailStr, isTestEmail) {
        const emailsKey = isTestEmail ? 'testEmails' : 'emails';

        if (!Utils.isEmailValid(emailStr)) {
            LxNotificationService.error(Translation.translate('SERVER_ERROR_INVALID_EMAIL'));

            return;
        }

        const lang = Translation.inputLanguage;

        vm.newsletter[emailsKey] = vm.newsletter[emailsKey] || {};
        vm.newsletter[emailsKey][lang] = vm.newsletter[emailsKey][lang] || [];

        vm.newsletter[emailsKey][lang].push(emailStr);
        vm.newEmail = {};
        vm.newGoogleGroups = [];
    }

    /**
     * Adds a new Google group to the list in the user management area.
     */
    function _addGoogleGroups() {
        map(vm.newGoogleGroups, (groupId) => {
            Feed.getFeedById(groupId).then((feed) => {
                if (angular.isDefinedAndFilled(feed)) {
                    feed.groups.forEach(({ group }) => {
                        _addTextEmail(group, false);
                    });
                }
            });
        });
    }

    /**
     * Get the base url.
     *
     * The base url is used for all "static" links within the newsletter.
     * (e.g. If you don't see this email properly click here).
     *
     * @return {string} The base url.
     */
    function _getBaseUrl() {
        // Not using $location because window.location is a bit more clever when it comes to ports.
        const { host } = $window.location;
        const instance = Instance.getInstance();
        const customer = Customer.getCustomer();

        // User is viewing the newsletter from a custom host.
        if (
            !includes(host, 'sites.lumapps.com') &&
            !includes(host, 'appspot.com') &&
            !includes(host, 'sites-eu.lumapps.com')
        ) {
            return `${$window.location.protocol}//${host}`;
        }

        // There's a host defined on the instance.
        if (angular.isDefinedAndFilled(get(instance, 'hosts'))) {
            return `http://${instance.hosts[0]}`;
        }

        // There's a host defined on the customer.
        if (angular.isDefinedAndFilled(get(customer, 'hosts'))) {
            return `http://${customer.hosts[0]}`;
        }

        return InitialSettings.SERVER_BASE_URL;
    }

    /**
     * Return the compiled HTML of the newsletter.
     *
     * @return {string} The full HTML of the newsletter.
     */
    function _getCompiledHtml() {
        let returnString = '';
        const contentWrapper = $element.find('.js-newsletter-content');

        map(contentWrapper, (el) => {
            returnString += el.outerHTML;
        });

        return returnString;
    }

    /**
     * The callback function to execute once the newsletter is saved and needs to be sent.
     */
    function _onSaveNewsletterSuccess() {
        const params = {
            html: vm.newsletter.html[Translation.inputLanguage],
            lang: Translation.inputLanguage,
            newsletterId: vm.newsletter.id,
            toTesters: vm.sendMode === 'test',
        };

        Newsletter.sendNewsletter(
            params,
            (response) => {
                if (angular.isDefinedAndFilled(response)) {
                    LxNotificationService.success(Translation.translate('ADMIN.NEWSLETTER.NEWSLETTER_SENT'));
                } else if (params.toTesters) {
                    LxNotificationService.error(Translation.translate('ADMIN_NEWSLETTER_SEND_TESTERS_ERROR'));
                } else {
                    LxNotificationService.error(Translation.translate('ADMIN.NEWSLETTER.SEND_ERROR'));
                }
                LxDialogService.close(vm.DIALOG_SEND_ID);
            },
            (err) => {
                Utils.displayServerError(err);
                LxDialogService.close(vm.DIALOG_SEND_ID);
            },
        );
    }

    /**
     * Set the HTML content of the newsletter.
     */
    function _setNewsletterHtml() {
        vm.newsletter.html = get(vm.newsletter, 'html', {});
        vm.newsletter.html[Translation.inputLanguage] = _getCompiledHtml();
    }

    /**
     * Fix the content list to correctly save the newsletter.
     *
     * @param {Object} newsletter The newsletter object to modify.
     */
    function _setNewsletterContentList(newsletter) {
        newsletter.contents = map(vm.contentList, 'id');

        if (angular.isUndefinedOrEmpty(newsletter.contentBlocks)) {
            return;
        }

        map(newsletter.contentBlocks, (contentBlock) => {
            if (angular.isDefined(vm.contentBlockContents[contentBlock.uuid])) {
                contentBlock.contents = map(vm.contentBlockContents[contentBlock.uuid], 'id');
            }
        });
    }

    /**
     * Retrieve the full contents of the newsletter or of a given content block and set them.
     *
     * @param {Object} [contentBlock] A newsletter content block.
     */
    function _setNewsletterContents(contentBlock) {
        const hasBlock = angular.isDefinedAndFilled(contentBlock);
        const ids = hasBlock ? contentBlock.contents : vm.newsletter.contents;

        Content.filterize(
            {
                ids,
                queryType: 'getContentByIds',
                status: [Config.CONTENT_STATUS.LIVE.value],
            },
            (response) => {
                if (hasBlock) {
                    vm.contentBlockContents[contentBlock.uuid] = response;
                } else {
                    vm.contentList = response;
                }
            },
            undefined,
            hasBlock ? contentBlock.uuid : vm.KEY_CONTENT_LIST,
        );
    }

    /**
     * Get the contents of a newsletter.
     *
     * @param {Object} newsletter The newsletter to get the contents of.
     */
    function _getNewsletterContents(newsletter) {
        if (angular.isUndefinedOrEmpty(newsletter.contentBlocks)) {
            return;
        }

        map(newsletter.contentBlocks, (contentBlock) => {
            if (angular.isDefinedAndFilled(contentBlock.contents)) {
                _setNewsletterContents(contentBlock);
            } else {
                vm.contentBlockContents[contentBlock.uuid] = [];
            }
        });
    }

    /**
     * Apply necessary html modifications to have better html client compatibility and to
     * avoid complicating the legacy froala configuration that will be removed anyway with future migration.
     * @param {string} html Html to format
     * @returns {string} The full formatted html
     */
    function _formatHtml(html = '') {
        let modifiedHtml;

        // Catch any formatting error in case anything goes wrong.
        try {
            // Get parsed html
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(html, 'text/html');

            /**
             * Manage images formatting.
             */
            parsedHtml.documentElement.querySelectorAll('img').forEach((img) => {
                const imageWith = img?.style?.width;
                /**
                 * If a css width is set on the img, also set width html attribute for
                 * better email client compatibility.
                 */
                if(imageWith){
                    img.setAttribute('width', imageWith.replace('px', ''));
                }
            });

            modifiedHtml = parsedHtml?.body?.innerHTML || html;
        } catch (error) {
            // If anything goes wrong during email parsing, return initial html.
            modifiedHtml = html;
        }

        return modifiedHtml;
    }

    /**
     * Sanitize the given HTML content to avoid all unwanted strings and attributes.
     */
    function _sanitizeHtml(html = '') {
        // Return sanitized html
        return sanitizeHTML(html, { useProfiles : { html: true }});
    }

    /**
     * Apply all the necessary transformation and sanitizing to the html before display / save.
     *
     * @param {string} html
     * @returns {string} The cleaned up html
     */
    function _cleanUpHtml(html = '') {
        const formattedHtml = _formatHtml(html);
        const sanitizedHtml = _sanitizeHtml(formattedHtml);

        return sanitizedHtml;
    }

    /**
     * Parse the given dictionary to clean up the html.
     *
     * @param {object}  dictionary    An object of lang / text to clean up.
     */
    function _cleanUpHtmlDictionary(dictionary = {}) {
        return Object.keys(dictionary).reduce(
            (acc, curr) => ({
                ...acc,
                [curr]: _cleanUpHtml(dictionary[curr]),
            }),
            {},
        );
    }

    /**
     * Parse all blocks to format/sanitize the html.
     */
    function _cleanUpNewsletter() {
        vm.newsletter.text = _cleanUpHtmlDictionary(vm.newsletter.text);
        if (vm.newsletter.contentBlocks?.length > 0) {
            vm.newsletter.contentBlocks.forEach((contentBlock, key) => {
                vm.newsletter.contentBlocks[key].text = _cleanUpHtmlDictionary(contentBlock.text);
            });
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add a newsletter block with name, intro, text and content selection.
     */
    function addNewsletterBlock() {
        vm.newsletter.contentBlocks = vm.newsletter.contentBlocks || [];

        const uuid = generateUUID();

        vm.newsletter.contentBlocks.push({
            contents: [],
            intro: {},
            name: {},
            text: {},
            uuid,
        });

        vm.contentBlockContents[uuid] = [];
    }

    /**
     * Add a new email to the production or test list.
     *
     * @param {boolean} isTestEmail Indicates if we want to add an email to the test list or the production list.
     */
    function addEmail(isTestEmail) {
        if (isTestEmail) {
            _addTextEmail(vm.newEmail.test, true);
        } else if (angular.isDefinedAndFilled(vm.newGoogleGroups)) {
            _addGoogleGroups();
        } else {
            _addTextEmail(vm.newEmail.to, false);
        }
    }

    /**
     * Close the settings dialog when press "enter".
     * If the check of the active parameter is not valid, the dialog stays open.
     *
     * @param {boolean} [check] The result of the check of the active parameter.
     */
    function closeSettings(check) {
        if (!check) {
            return;
        }

        LxDialogService.close(vm.DIALOG_SETTINGS_ID);
    }

    /**
     * Delete the current image.
     */
    function deleteNewsletterImage() {
        vm.newsletter.image[Translation.inputLanguage] = undefined;
    }

    /**
     * Open content picker.
     *
     * @param {string} [contentBlockUuid] The identifier of the content block to change the selection of.
     */
    function openContentPicker(contentBlockUuid) {
        const dialogId = angular.isDefinedAndFilled(contentBlockUuid)
            ? `#newsletter-content-block-${contentBlockUuid}`
            : '#newsletter';

        Utils.waitForAndExecute(dialogId, ContentPicker);
    }

    /**
     * Open send dialog.
     */
    function openSendDialog() {
        Utils.waitForAndExecute(`#${vm.DIALOG_SEND_ID}`);
    }

    /**
     * Open settings dialog.
     */
    function openSettingsDialog() {
        Utils.waitForAndExecute(`#${vm.DIALOG_SETTINGS_ID}`);
    }

    /**
     * Remove a given content block.
     *
     * @param {string} uuid The identifier of the content block to be removed.
     */
    function removeContentBlock(uuid) {
        if (angular.isUndefinedOrEmpty(uuid)) {
            return;
        }

        LxNotificationService.confirm(
            Translation.translate('ADMIN.NEWSLETTER.DELETE_SECTION'),
            Translation.translate('ADMIN.NEWSLETTER.DELETE_SECTION_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            (answer) => {
                if (!answer) {
                    return;
                }

                Utils.reject(vm.newsletter.contentBlocks, {
                    uuid,
                });
            },
        );
    }

    /**
     * Remove an email (or test email) from the list.
     *
     * @param {number}  index       The index of the email to be removed in the list.
     * @param {boolean} isTestEmail Indicates if we want to remove a test email or a regular one.
     */
    function removeEmail(index, isTestEmail) {
        const description = 'ADMIN.NEWSLETTER.DELETE_EMAIL_DESCRIPTION';
        const key = isTestEmail ? 'testEmails' : 'emails';
        const title = 'ADMIN.NEWSLETTER.DELETE_EMAIL';

        LxNotificationService.confirm(
            Translation.translate(title),
            Translation.translate(description),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            (answer) => {
                if (!answer) {
                    return;
                }

                vm.newsletter[key][Translation.inputLanguage].splice(index, 1);
            },
        );
    }

    /**
     * Save the selected newsletter.
     *
     * @param {Function} [cb] A callback function to execute when the newsletter is saved successfully.
     */
    function save(cb) {
        if (!Translation.hasTranslations(vm.newsletter.name)) {
            LxNotificationService.error(Translation.translate('ADMIN_NEWSLETTER_ERROR_NAME_REQUIRED'));
        }

        cb = cb || angular.noop;

        vm.isRendering = true;

        $timeout(function timedOutSave() {
            _setNewsletterHtml();

            const newsletterObject = angular.fastCopy(vm.newsletter);

            _setNewsletterContentList(newsletterObject);

            Newsletter.save(
                newsletterObject,
                (response) => {
                    LxNotificationService.success(Translation.translate('GLOBAL.SAVED'));

                    vm.isRendering = false;

                    if (angular.isUndefined(vm.newsletter.id)) {
                        $state.go('app.admin.newsletter-edit', {
                            key: response.id,
                        });
                    }

                    cb(response);
                },
                (err) => {
                    Utils.displayServerError(err);

                    vm.isRendering = false;
                    vm.isSending = false;
                },
            );
        });
    }

    /**
     * Send newsletter.
     */
    function sendNewsletter() {
        vm.isSending = true;

        // Save the newsletter before sending it.
        vm.save(_onSaveNewsletterSuccess);
    }

    /**
     * Computes the complete URL of the site's logo.
     * @param {Number} size the width of the logo.
     * @return {String} The absolute URL of the current site's logo.
     */
    function getInstanceLogo(size) {
        let url = Utils.getMediaUrl(Instance.getLogo(size));
        /* Instance.getLogo doesn't consistently return an absolute URL (nor does Utils.getMedialURl()
         * but sometimes a '/serve/hash' URL. This wrapper makes sure an absolute URL is included
         * in newsletters so that images don't break in the recipients' mailbox.
         */
        if (url.startsWith('/')) {
            url = `${_getBaseUrl()}${url}`;
        }

        return url;
    }

    /**
     * Get blobKey of uploaded image.
     *
     * @param {Object} response The response object from the uploader callback.
     */
    function uploadNewsletterImage(response) {
        set(vm.newsletter, `image.${Translation.inputLanguage}`, response.url);
    }

    function getCleanedHtml(html = '') {
        return _cleanUpHtml(html);
    }

    /////////////////////////////

    vm.addEmail = addEmail;
    vm.addNewsletterBlock = addNewsletterBlock;
    vm.closeSettings = closeSettings;
    vm.deleteNewsletterImage = deleteNewsletterImage;
    vm.openContentPicker = openContentPicker;
    vm.openSendDialog = openSendDialog;
    vm.openSettingsDialog = openSettingsDialog;
    vm.removeContentBlock = removeContentBlock;
    vm.removeEmail = removeEmail;
    vm.save = save;
    vm.sendNewsletter = sendNewsletter;
    vm.uploadNewsletterImage = uploadNewsletterImage;
    vm.getInstanceLogo = getInstanceLogo;
    vm.getCleanedHtml = getCleanedHtml;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Whenever we close the send newsletter dialog, reset the isSending flag.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} dialogId The id of the dialog being closed.
     */
    $scope.$on('lx-dialog__close-end', (evt, dialogId) => {
        if (dialogId === vm.DIALOG_SEND_ID) {
            vm.isSending = false;
        }
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        // Init the options of Froala.
        vm.FROALA_OPTIONS = FroalaService.getOptions(true, true, true, true, _NEWSLETTER_TOOLBAR_BUTTONS);

        _cleanUpNewsletter();

        const hasContent = angular.isDefinedAndFilled(get(vm.newsletter, 'contents'));

        vm.contentList = [];

        if (hasContent) {
            _setNewsletterContents();
        }

        vm.baseUrl = _getBaseUrl();

        const newsletterFeeds = UserAccess.getFeedsForAction('NEWSLETTER_EDIT');

        // If the user has restriction feeds on newsletters and isn't an admin.
        if (angular.isDefinedAndFilled(newsletterFeeds) && !UserAccess.isUserAllowed()) {
            vm.restrictedFeeds = newsletterFeeds;
        }

        _getNewsletterContents(vm.newsletter);
    }

    init();
}

/////////////////////////////

/**
 * Displays a newsletter.
 *
 * @param {Object} newsletter A newsletter object to display.
 */

function NewsletterThemeDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: NewsletterThemeController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            newsletter: '<',
        },
        templateUrl: '/client/back-office/modules/newsletter/common/views/newsletter-theme.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('newsletterTheme', NewsletterThemeDirective);

/////////////////////////////

export { NewsletterThemeDirective };
