import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { fixBackendMissingUTC } from '@lumapps/utils/date/fixBackendMissingUTC';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function ModuleTutorialAdminController($scope, $timeout, Config, Content, ContentForm, Feed, FormValidation,
        FroalaService, LxDialogService, LxNotificationService, ModuleAdmin, Translation, Tutorial, UploaderAction,
        User, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The Froala Toolbar config.
         *
         * @type {Array}
         * @constant
         * @readonly
         */
        var _FROALA_TOOL_BAR = ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '-',
            'fontFamily', 'fontSize', '|', 'color', 'inlineStyle', 'paragraphStyle', '|',
            'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertHR', '-',
            'insertLink', 'insertVideo', '|',
            'undo', 'redo', 'clearFormatting', 'selectAll', 'html',
        ];

        /**
         * An empty slide.
         *
         * @type {Date}
         */
        var _emptySlide = {
            image: {},
            position: undefined,
            text: undefined,
            uuid: undefined,
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The unique identifier of the dialog.
         *
         * @type {string}
         */
        vm.DIALOG_ID = ModuleAdmin.dialogKeys.tutorial;

        /**
         * Options for the Froala WYSIWYG editor.
         *
         * @type {Object}
         * @constant
         */
        vm.FROALA_OPTIONS = {};

        /**
         * The min date pickable as the end date.
         *
         * @type {Date}
         */
        vm.MIN_DATE = new Date();

        /**
         * The tab that is active.
         *
         * @type {number}
         */
        vm.activeTab = 0;

        /**
         * The current slide.
         *
         * @type {Object}
         */
        vm.activeSlide = undefined;

        vm.slidesImageUrl = {};

        /**
         * The image type permitted.
         *
         * @type {string}
         */
        vm.allowedImageType = Config.FILE_TYPES.IMAGE;

        /**
         * The type of slide.
         *
         * @type {Object}
         */
        vm.form = {};

        /**
         * Whether or not the tutorial is saving at the moment.
         *
         * @type {boolean}
         */
        vm.isSaving = false;

        /////////////////////////////

        /*
         * Services and utilities.
         */
        vm.Content = Content;
        vm.ContentForm = ContentForm;
        vm.Feed = Feed;
        vm.FormValidation = FormValidation;
        vm.FroalaService = FroalaService;
        vm.ModuleAdmin = ModuleAdmin;
        vm.Translation = Translation;
        vm.Tutorial = Tutorial;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Add a new slide to the current tutorial.
         */
        function addTutorialSlide() {
            vm.activeSlide = angular.fastCopy(_emptySlide);
            var tutorialSildes = Tutorial.getCurrent().slides;
            vm.activeSlide.position = (angular.isDefined(tutorialSildes)) ? tutorialSildes.length + 1 : 1;
            vm.activeSlide.uuid = generateUUID();

            vm.disableActions = true;

            formatSlideUrls([...tutorialSlides, vm.activeSlide]);
        }

        /**
         * Close the slide settings.
         */
        function closeTutorialSlide() {
            vm.activeSlide = undefined;
            vm.disableActions = false;
        }

        /**
         * Remove a slide from the current tutorial and re-index the slides position.
         *
         * @param {number} index The index of the slide to remove.
         */
        function removeTutorialSlide(index) {
            var tutorialSlides = Tutorial.getCurrent().slides;
            tutorialSlides.splice(index, 1);

            // Update element position.
            angular.forEach(tutorialSlides, function updateElement(value, key) {
                value.position = key;
            });

            vm.closeTutorialSlide();

            formatSlideUrls(tutorialSlides);
        }

        /**
         * Delete a slide and warn the user.
         *
         * @param {number} index The slide index to delete.
         */
        function deleteTutorialSlide(index) {
            LxNotificationService.confirm(
                Translation.translate('ADMIN_MODULE_TUTORIAL_SLIDE_DELETE'),
                Translation.translate('ADMIN_MODULE_TUTORIAL_SLIDE_DELETE_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                }, function removeSlide(answer) {
                    if (answer) {
                        removeTutorialSlide(index);
                    }
                });
        }

        /**
         * Open and populate a slide settings.
         *
         * @param {Object} slide The slide to edit.
         */
        function editTutorialSlide(slide) {
            var tutorialSlides = Tutorial.getCurrent().slides;
    
            vm.disableActions = true;
            vm.activeSlide = angular.fastCopy(slide);

            formatSlideUrls([...tutorialSlides, vm.activeSlide]);
        }

        /**
         * Gets start date of the tutorial to be displayed.
         *
         * @return {Date} The start date of the tutorial.
         */
        function getStartAt() {
            if (angular.isUndefinedOrEmpty(_.get(vm.startAt, 'dateStart'))) {
                return undefined;
            }

            return moment(vm.startAt.dateStart).local().startOf('day').toISOString();
        }

        /**
         * Gets end date of the tutorial to be displayed.
         *
         * @return {Date} The end date of the tutorial.
         */
        function getEndAt() {
            if (angular.isUndefinedOrEmpty(_.get(vm.endAt, 'dateEnd'))) {
                return undefined;
            }

            return moment(vm.endAt.dateEnd).local().endOf('day').toISOString();
        }

        /**
         * Save the tutorial.
         */
        function save() {
            var listKey = ModuleAdmin.getListKey();
            if (ContentForm.checkContent(false, false)) {
                var content = Content.getCurrent(listKey);
                var tutorial = Tutorial.getCurrent();
                tutorial.name = content.title;
                tutorial.feeds = content.feedKeys;
                tutorial.startAt = vm.getStartAt();
                tutorial.endAt = vm.getEndAt();

                if (angular.isDefinedAndFilled(tutorial.startAt)) {
                    tutorial.displayAgainTime = tutorial.startAt;
                }

                vm.isSaving = true;

                Tutorial.save(tutorial, function onTutorialSaveSuccess(response) {
                    content.externalKey = response.id;
                    content.uid = content.uid || response.uid;

                    // If authorDetails isn't set, the display is `undefined undefined`.
                    if (angular.isUndefinedOrEmpty(content.authorDetails)) {
                        var user = User.getConnected();
                        // Set user details for display purposes.
                        content.authorDetails = {
                            firstName: user.firstName,
                            lastName: user.lastName,
                        };
                    }

                    Content.setCurrent(content, listKey);
                    ModuleAdmin.updateList(content);

                    LxNotificationService.success(Translation.translate('ADMIN_MODULE_SAVE_SUCCESS'));
                    LxDialogService.close(vm.DIALOG_ID);
                    vm.isSaving = false;
                }, function onTutorialSaveError(err) {
                    vm.isSaving = false;
                    Utils.displayServerError(err);
                }, listKey);
            } else {
                vm.activeTab = 0;

                $timeout(function delayInvalidatingForm() {
                    if (angular.isDefined(vm.form.moduleTutorial)) {
                        FormValidation.setFormDirty(vm.form.moduleTutorial);
                    }
                });
            }
        }

        /**
         * Check the slide form and if everything is okay add the slide to the tutorial slides.
         */
        function saveTutorialSlide() {
            var currentTutorial = Tutorial.getCurrent();

            if (_.get(vm.form.moduleTutorialSlide, '$invalid', false)) {
                vm.form.moduleTutorialSlide.title.$setDirty();

                return;
            }

            if (!Translation.hasTranslations(vm.activeSlide.image, true) &&
                !Translation.hasTranslations(vm.activeSlide.text, true)) {
                LxNotificationService.error(
                    Translation.translate('ADMIN_MODULE_TUTORIAL_SLIDE_IMAGE_OR_TEXT_REQUIRED'));

                return;
            }

            var isNew = true;
            var slideToSave = {
                image: vm.activeSlide.image,
                text: vm.activeSlide.text,
                title: vm.activeSlide.title,
                uuid: vm.activeSlide.uuid,
            };

            currentTutorial.slides = currentTutorial.slides || [];

            angular.forEach(currentTutorial.slides, function forEachSlide(value, index) {
                if (value.uuid === slideToSave.uuid) {
                    isNew = false;
                    currentTutorial.slides[index] = slideToSave;
                }
            });

            if (isNew) {
                currentTutorial.slides.push(slideToSave);
            }

            vm.closeTutorialSlide();

            formatSlideUrls(currentTutorial.slides);
        }

        /**
         * Get blobKey of an uploaded image.
         *
         * @param {Object} response The blobkey.
         */
        function uploadTutorialImage(response) {
            vm.activeSlide.image = vm.activeSlide.image || {};

            if (response) {
                vm.activeSlide.image[Translation.inputLanguage] = response.id;
            } else {
                vm.activeSlide.image[Translation.inputLanguage] = undefined;
            }
        }

        function formatSlideUrls(slides) {
            if (!slides) {
                slides = Tutorial.getCurrent().slides;
            }

            slides.forEach((slide) => {
                if (Boolean(slide)) {
                    const { uuid, image } = slide;

                    Object.keys(image).forEach((lang) => {
                        if (!vm.slidesImageUrl[uuid]) {
                            vm.slidesImageUrl[uuid] = {};
                        }

                        vm.slidesImageUrl[uuid][lang] = getSlideImageUrl(image[lang]);
                    });
                }
            });
        }

        function getSlideImageUrl(image) {
            if (image) {
                return {
                    url: Utils.getMediaUrl('/serve/' + image),
                    id: image,
                };
            }

            return undefined;
        }

        /////////////////////////////

        vm.addTutorialSlide = addTutorialSlide;
        vm.closeTutorialSlide = closeTutorialSlide;
        vm.deleteTutorialSlide = deleteTutorialSlide;
        vm.getSlideImageUrl = getSlideImageUrl;
        vm.getEndAt = getEndAt;
        vm.getStartAt = getStartAt;
        vm.editTutorialSlide = editTutorialSlide;
        vm.removeTutorialSlide = removeTutorialSlide;
        vm.save = save;
        vm.saveTutorialSlide = saveTutorialSlide;
        vm.uploadTutorialImage = uploadTutorialImage;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        $scope.$watch('vm.startAt.dateFormatted', function(newValue, oldValue) {
            if (angular.isUndefinedOrEmpty(newValue)) {
                vm.startAt.dateStart = undefined;
            }
        });

        $scope.$watch('vm.endAt.dateFormatted', function(newValue, oldValue) {
            if (angular.isUndefinedOrEmpty(newValue)) {
                vm.endAt.dateEnd = undefined;
            }
        });

        /**
         * Watch input language lang change to reset from pristine.
         */
        $scope.$on('inputLanguage', function onInputLanguageChange() {
            if (angular.isDefined(vm.form.moduleTutorial)) {
                vm.form.moduleTutorial.$setPristine();
            }

            if (angular.isDefined(vm.form.moduleTutorialSlide)) {
                vm.form.moduleTutorialSlide.$setPristine();
            }

            const currentTutorial = Tutorial.getCurrent();
            formatSlideUrls(currentTutorial.slides);
        });

        /**
         * Gets and formats previously set dates for the tutorial to be displayed.
         *
         * @param {Event}  evt      The event triggering this method.
         * @param {string} dialogId The id of the dialog that triggered this function.
         */
        $scope.$on('lx-dialog__open-start', function onTutorialDialogOpenStart(evt, dialogId) {
            if (vm.DIALOG_ID !== dialogId) {
                return;
            }

            ContentForm.setActiveListKey(ModuleAdmin.getListKey());
            var currentTutorial = Tutorial.getCurrent();

            if ((angular.isDefinedAndFilled(currentTutorial.startAt))) {
                vm.startAt = {
                    dateStart:
                        angular.isDefinedAndFilled(currentTutorial.startAt) ?
                        moment.utc(fixBackendMissingUTC(currentTutorial.startAt)).local() :
                        undefined,
                };
                vm.startAt.dateFormatted = moment.utc(vm.startAt.dateStart).local().format('LL');
            }

            if ((angular.isDefinedAndFilled(currentTutorial.endAt))) {
                vm.endAt = {
                    dateEnd:
                        angular.isDefinedAndFilled(currentTutorial.endAt) ?
                        moment.utc(fixBackendMissingUTC(currentTutorial.endAt)).local() :
                        undefined,
                };
                vm.endAt.dateFormatted = moment.utc(vm.endAt.dateEnd).local().format('LL');
            }

            var content = Content.getCurrent(ModuleAdmin.getListKey());
            angular.forEach(content.title, function forEachTitle(title, lang) {
                ContentForm.setSlugFromTitle(content.title[lang], lang);
            });

            formatSlideUrls();
        });

        /**
         * Empties the dates stored in order to have fresh dialogs in the future.
         *
         * @param {Event}  evt      The event triggering this method.
         * @param {string} dialogId The id of the dialog that triggered this function.
         */
        $scope.$on('lx-dialog__close-start', function onTutorialDialogCloseStart(evt, dialogId) {
            if (vm.DIALOG_ID !== dialogId) {
                return;
            }
            vm.startAt = {};
            vm.endAt = {};
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.FROALA_OPTIONS = FroalaService.getOptions(false, false, false, true, _FROALA_TOOL_BAR);
            vm.MIN_DATE.setHours(0, 0, 0, 0);
        }

        init();
    }

    angular.module('Controllers').controller('ModuleTutorialAdminController', ModuleTutorialAdminController);
})();
