(function IIFE() {
    'use strict';

    /////////////////////////////

    function ContentSidebarRevisionController(
        $compile,
        $q,
        $rootScope,
        $scope,
        Content,
        Features,
        LxNotificationService,
        Revision,
        Translation,
        User,
        Utils,
    ) {
        'ngInject';

        var vm = this;
        var previousContributionMode = undefined;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Features = Features;
        vm.Revision = Revision;
        vm.User = User;
        vm.Utils = Utils;
        vm.Content = Content;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if the current content has changed and confirm if the user wants to discard changes.
         *
         * @return {Promise} A promise that resolves when there are no changes or if they're discarded.
         */
        function _contentHasChangedRestoreContent() {
            return $q(function promise(resolve, reject) {
                if (!Content.hasChanges()) {
                    resolve();

                    return;
                }

                LxNotificationService.confirm(
                    Translation.translate('DISCARD_UNSAVED_CHANGES_CONFIRM'),
                    Translation.translate('DISCARD_UNSAVED_CHANGES_CONFIRM_DESC'),
                    {
                        cancel: Translation.translate('CANCEL'),
                        ok: Translation.translate('OK'),
                    },
                    function confirmDiscardUnsavedChangesCallback(answer) {
                        if (!answer) {
                            reject();

                            return;
                        }

                        resolve();
                    },
                );
            });
        }

        /**
         * Exit revision mode.
         */
        function _exitRevisionMode() {
            var content = Content.getCurrent();

            if (angular.isUndefinedOrEmpty(content)) {
                return;
            }

            if (Content.originalContent.id !== content.id) {
                Content.setCurrent(Content._formatServerObjectToClient(Content.originalContent));
                content = Content.getCurrent();
            }

            $scope.$parent.vm.revisionMode = false;
            // Reset version number with original version.
            vm.currentRevisionVersion = Content.originalContent.version;

            if (Content.isDesignerInNewSimpleMode()) {
                if (previousContributionMode === 'WRITER_MODE') {
                    Content.setContributionMode('writer');
                } else if (previousContributionMode === 'BUILDER_MODE') {
                    Content.setContributionMode('builder');
                } else if (previousContributionMode === 'EXPERT_MODE') {
                    Content.setContributionMode('expert');
                }
            } else {
                if (content.templatefixedLayout && content.templatefixedWidgets) {
                    Content.setContributionMode(previousContributionMode);
                } else {
                    Content.setViewMode('default');
                }
            }
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Restore current revision as a new draft.
         */
        function restoreContentAsDraft(evt) {
            if (evt) {
                evt.stopPropagation();
            }

            const originalContent = Content.originalContent;
            const revision = Content.getCurrent();

            _exitRevisionMode();
            $scope.$emit('exit-revision');

            $scope.$emit('save-revision-as-draft', originalContent, revision);
        }

        /**
         * Restore a specific version of a content.
         *
         * @param {string} version The version number of the revision to restore.
         */
        function restoreContent(version, evt) {
            if (evt) {
                evt.stopPropagation();
            }

            $scope.$emit('show-loader');

            Revision.restore(
                {
                    uid: Content.originalContent.id,
                    version: version,
                },
                function onRevisionRestoreSuccess(response) {
                    // Show restored content.
                    Content.setCurrent(response);
                    Content.originalContent = angular.fastCopy(response);

                    // Hide restore bar.
                    $rootScope.$broadcast('hide-loader');

                    // Change tab (and change action).
                    _exitRevisionMode();

                    // Show a success notification
                    LxNotificationService.success(Translation.translate('CONTENT.RESTORED'));
                },
                function onRevisionRestoreError(err) {
                    $rootScope.$broadcast('hide-loader');
                    Utils.displayServerError(err);
                },
            );
        }

        /**
         * Show a specific version of the content.
         *
         * @param {string} version The version number of the revision to show.
         */
        function showRevision(version) {
            $scope.$emit('show-loader');

            Revision.get(
                {
                    uid: Content.originalContent.id,
                    version: version,
                },
                function onRevisionGetSuccess(response) {
                    Content.setCurrent(Content._formatServerObjectToClient(response.content));
                    $rootScope.$broadcast('hide-loader');
                },
                function onRevisionGetError(err) {
                    Utils.displayServerError(err);
                    $rootScope.$broadcast('hide-loader');
                },
            );
        }

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the controller is destroyed, exit the revision mode.
         */
        $scope.$on('$destroy', _exitRevisionMode);

        /////////////////////////////

        vm.restoreContentAsDraft = restoreContentAsDraft;
        vm.restoreContent = restoreContent;
        vm.showRevision = showRevision;
        vm.exitRevisionMode = _exitRevisionMode;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            _contentHasChangedRestoreContent().then(
                function listRevisionsAndShowLatest() {
                    previousContributionMode = Content.getCurrentContributionMode();
                    $scope.$parent.vm.revisionMode = true;

                    Content.setViewMode('locked');

                    Revision.filterize(
                        {
                            contentId: Content.originalContent.id,
                        },
                        function onRevisionListSuccess(response) {
                            Revision.setCurrent(_.first(response));
                            showRevision(Content.originalContent.version);
                        },
                    );
                },
                function onRevisionListError() {
                    _exitRevisionMode();

                    // Change tab (and change action).
                    $scope.$emit('exit-revision');
                },
            );
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('ContentSidebarRevisionController', ContentSidebarRevisionController);
})();
